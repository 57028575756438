import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Input, Row, Switch} from 'antd';
import {BASEURL} from '../Constant/Constant';
import {toast} from 'react-hot-toast';
import {LoadingContext} from '../Context/LoadingContext';
import {useNavigate, useParams} from 'react-router-dom';
import axiosClient from '../Constant/axiossetup';
import {pickBy} from 'lodash';

const UpdatGoogleAds = () => {
  const [form] = Form.useForm();
  const {packagename} = useParams();
  const {setLoading} = useContext(LoadingContext);

  const [IsActiveAppOpen, setIsActiveAppOpen] = useState(true);
  const [IsActiveAdaptiveBanner, setIsActiveAdaptiveBanner] = useState(true);
  const [IsActiveBanner, setIsActiveBanner] = useState(true);
  const [IsActiveInterstitial, setIsActiveInterstitial] = useState(true);
  const [IsActiveInterstitialVideo, setIsActiveInterstitialVideo] = useState(true);
  const [IsActiveRewarded, setIsActiveRewarded] = useState(true);
  const [IsActiveRewardedInterstitial, setIsActiveRewardedInterstitial] = useState(true);
  const [IsActiveNativeAdvanced, setIsActiveNativeAdvanced] = useState(true);
  const [IsActiveNativeAdvancedVideo, setIsActiveNativeAdvancedVideo] = useState(true);

  const [settingId, setSettingId] = useState('');
  const navigate = useNavigate();

  const updateAdsData = async (object: any) => {
    try {
      const response = await axiosClient.post('/applist/google', {
        ...object,
      });

      if (response?.data && response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response?.data?.message || 'Something Went Wrong');
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Something Went Wrong');
    }
  };

  const handleOk = (values: any) => {
    const cleanedObject = pickBy(values, ele => ele !== undefined);
    const obj = {
      ...cleanedObject,
      settingId,
    };
    void updateAdsData(obj);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get(`${BASEURL}/applist/${String(packagename)}`);
      if (response?.data && response.data.success) {
        setLoading(false);
        toast.success(response?.data?.message);
        setSettingId(response?.data?.data?.id);

        const isGoogleAdaptiveBanner = response?.data?.data?.GoogleAds[0]?.isGoogleAdaptiveBanner;
        if (isGoogleAdaptiveBanner !== undefined) {
          setIsActiveAdaptiveBanner(isGoogleAdaptiveBanner);
        }

        const isGoogleAppOpen = response?.data?.data?.GoogleAds[0]?.isGoogleAppOpen;
        if (isGoogleAppOpen !== undefined) {
          setIsActiveAppOpen(isGoogleAppOpen);
        }

        const isGoogleBanner = response?.data?.data?.GoogleAds[0]?.isGoogleBanner;
        if (isGoogleBanner !== undefined) {
          setIsActiveBanner(isGoogleBanner);
        }

        const isGoogleInterstitial = response?.data?.data?.GoogleAds[0]?.isGoogleInterstitial;
        if (isGoogleInterstitial !== undefined) {
          setIsActiveInterstitial(isGoogleInterstitial);
        }

        const isGoogleInterstitialVideo = response?.data?.data?.GoogleAds[0]?.isGoogleInterstitialVideo;
        if (isGoogleInterstitialVideo !== undefined) {
          setIsActiveInterstitialVideo(isGoogleInterstitialVideo);
        }

        const isGoogleNativeAdvanced = response?.data?.data?.GoogleAds[0]?.isGoogleNativeAdvanced;
        if (isGoogleNativeAdvanced !== undefined) {
          setIsActiveNativeAdvanced(isGoogleNativeAdvanced);
        }

        const isGoogleNativeAdvancedVideo = response?.data?.data?.GoogleAds[0]?.isGoogleNativeAdvancedVideo;
        if (isGoogleNativeAdvancedVideo !== undefined) {
          setIsActiveNativeAdvancedVideo(isGoogleNativeAdvancedVideo);
        }

        const isGoogleRewarded = response?.data?.data?.GoogleAds[0]?.isGoogleRewarded;
        if (isGoogleRewarded !== undefined) {
          setIsActiveRewarded(isGoogleRewarded);
        }

        const isGoogleRewardedInterstitial = response?.data?.data?.GoogleAds[0]?.isGoogleRewardedInterstitial;
        if (isGoogleRewardedInterstitial !== undefined) {
          setIsActiveRewardedInterstitial(isGoogleRewardedInterstitial);
        }

        form.setFieldsValue({
          googleAdaptiveBanner: response?.data?.data?.GoogleAds[0]?.googleAdaptiveBanner,
          googleAppOpen: response?.data?.data?.GoogleAds[0]?.googleAppOpen,
          googleBanner: response?.data?.data?.GoogleAds[0]?.googleBanner,
          googleInterstitial: response?.data?.data?.GoogleAds[0]?.googleInterstitial,
          googleInterstitialVideo: response?.data?.data?.GoogleAds[0]?.googleInterstitialVideo,
          googleNativeAdvanced: response?.data?.data?.GoogleAds[0]?.googleNativeAdvanced,
          googleNativeAdvancedVideo: response?.data?.data?.GoogleAds[0]?.googleNativeAdvancedVideo,
          googleRewarded: response?.data?.data?.GoogleAds[0]?.googleRewarded,
          googleRewardedInterstitial: response?.data?.data?.GoogleAds[0]?.googleRewardedInterstitial,
          isGoogleAppOpen: response?.data?.data?.GoogleAds[0]?.isGoogleAppOpen || IsActiveAppOpen,
          isGoogleAdaptiveBanner: response?.data?.data?.GoogleAds[0]?.isGoogleAdaptiveBanner || IsActiveAdaptiveBanner,
          isGoogleBanner: response?.data?.data?.GoogleAds[0]?.isGoogleBanner || IsActiveBanner,
          isGoogleInterstitial: response?.data?.data?.GoogleAds[0]?.isGoogleInterstitial || IsActiveInterstitial,
          isGoogleInterstitialVideo:
            response?.data?.data?.GoogleAds[0]?.isGoogleInterstitialVideo || IsActiveInterstitialVideo,
          isGoogleRewarded: response?.data?.data?.GoogleAds[0]?.isGoogleRewarded || IsActiveRewarded,
          isGoogleRewardedInterstitial:
            response?.data?.data?.GoogleAds[0]?.isGoogleRewardedInterstitial || IsActiveRewardedInterstitial,
          isGoogleNativeAdvanced: response?.data?.data?.GoogleAds[0]?.isGoogleNativeAdvanced || IsActiveNativeAdvanced,
          isGoogleNativeAdvancedVideo:
            response?.data?.data?.GoogleAds[0]?.isGoogleNativeAdvancedVideo || IsActiveNativeAdvancedVideo,
        });
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message || 'Something Went Wrong');
    }
  };

  useEffect(() => {
    void fetchData();
  }, []);

  return (
    <div>
      <Row justify={'center'}>
        <Col span={24}>
          <Card title={'Update Ads'}>
            <Form name="setting" form={form} onFinish={handleOk} layout="vertical" autoComplete="off">
              <Row gutter={[60, 30]}>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="googleAppOpen" label="Google App Open">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="isGoogleAppOpen" label="Active" initialValue={IsActiveAppOpen}>
                        <Switch
                          checked={IsActiveAppOpen}
                          onChange={e => {
                            setIsActiveAppOpen(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="googleAdaptiveBanner" label="Google  Adaptive Banner">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="isGoogleAdaptiveBanner" label="Active" initialValue={IsActiveAdaptiveBanner}>
                        <Switch
                          checked={IsActiveAdaptiveBanner}
                          onChange={e => {
                            setIsActiveAdaptiveBanner(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="googleBanner" label="Google Banner">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="isGoogleBanner" label="Active" initialValue={IsActiveBanner}>
                        <Switch
                          checked={IsActiveBanner}
                          onChange={e => {
                            setIsActiveBanner(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="googleInterstitial" label="Google Interstitial">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="isGoogleInterstitial" label="Active" initialValue={IsActiveInterstitial}>
                        <Switch
                          checked={IsActiveInterstitial}
                          onChange={e => {
                            setIsActiveInterstitial(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="googleInterstitialVideo" label="Google Interstitial Video">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="isGoogleInterstitialVideo"
                        label="Active"
                        initialValue={IsActiveInterstitialVideo}>
                        <Switch
                          checked={IsActiveInterstitialVideo}
                          onChange={e => {
                            setIsActiveInterstitialVideo(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="googleRewarded" label="Google Rewarded">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="isGoogleRewarded" label="Active" initialValue={IsActiveRewarded}>
                        <Switch
                          checked={IsActiveRewarded}
                          onChange={e => {
                            setIsActiveRewarded(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="googleRewardedInterstitial" label="Google Rewarded Interstitial">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="isGoogleRewardedInterstitial"
                        label="Active"
                        initialValue={IsActiveRewardedInterstitial}>
                        <Switch
                          checked={IsActiveRewardedInterstitial}
                          onChange={e => {
                            setIsActiveRewardedInterstitial(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="googleNativeAdvanced" label="Google Native Advanced">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="isGoogleNativeAdvanced" label="Active" initialValue={IsActiveNativeAdvanced}>
                        <Switch
                          checked={IsActiveNativeAdvanced}
                          onChange={e => {
                            setIsActiveNativeAdvanced(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="googleNativeAdvancedVideo" label="Google Native Advanced Video">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="isGoogleNativeAdvancedVideo"
                        label="Active"
                        initialValue={IsActiveNativeAdvancedVideo}>
                        <Switch
                          checked={IsActiveNativeAdvancedVideo}
                          onChange={e => {
                            setIsActiveNativeAdvancedVideo(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="mt-3">
                  Update Now
                </Button>
                <Button
                  htmlType="submit"
                  className="mt-3 mx-5"
                  onClick={() => {
                    navigate('/applist');
                  }}>
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UpdatGoogleAds;
