import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Drawer, Row, Table, Tag, Tooltip} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import axiosClient from '../Constant/axiossetup';
import {toast} from 'react-hot-toast';
import {LoadingContext} from '../Context/LoadingContext';
import dayjs from 'dayjs';
import {CloseOutlined, InfoCircleOutlined} from '@ant-design/icons';

interface DataType {
  blockReason: string;
  countryCode: string;
  createdAt: string;
  deletedAt: string;
  email: string;
  fullName: string;
  gender: string;
  id: string;
  ipAddress: string;
  lastActive: string;
  packageName: string;
  phoneNumber: string;
  platform: string;
  status: string;
  updatedAt: string;
  userBlock: string;
}

interface IUserLog {
  UserId: string;
  country: string;
  createdAt: string;
  id: string;
  ipAddress: string;
  lastActive: string;
  platform: string;
  updatedAt: string;
}

const Users = () => {
  const [rows, setRows] = useState([]);
  const [visible, setVisble] = useState(false);
  const [userLogs, setUserLogs] = useState([]);

  const {setLoading} = useContext(LoadingContext);

  const columns: ColumnsType<DataType> = [
    {
      title: 'Id',
      dataIndex: 'index',
      key: 'id',
      render: (value, item, index) => (1 - 1) * 10 + index + 1,
    },
    {
      title: 'Package Name',
      dataIndex: 'packageName',
      key: 'packageName',
    },
    {
      title: 'Ip Address',
      dataIndex: 'ipAddress',
      key: 'ipAddress',
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: (_, data) => (
        <>
          <Tag color="green">{data.platform === '1' ? 'Android' : data.platform === '2' ? 'Ios' : 'Both'}</Tag>
        </>
      ),
    },
    {
      title: 'Last Active',
      dataIndex: 'lastActive',
      key: 'lastActive',
      render: (_, data) => <div>{dayjs(data?.lastActive).format('DD/MM/YYYY')}</div>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, data) => (
        <>
          <Tag color={data.status === 'active' ? 'green' : 'red'}>
            {data.status === 'active' ? 'Active' : 'Inactive'}
          </Tag>
        </>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, data) => <div>{dayjs(data?.createdAt).format('DD/MM/YYYY')}</div>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div>
          <Tooltip title="View Logs">
            <Button
              type="primary"
              className="mx-1"
              icon={<InfoCircleOutlined />}
              onClick={() => {
                handleDetails(record);
              }}></Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const logColumns: ColumnsType<IUserLog> = [
    {
      title: 'Id',
      dataIndex: 'index',
      key: 'id',
      render: (value, item, index) => (1 - 1) * 10 + index + 1,
    },
    {
      title: 'Ip Address',
      dataIndex: 'ipAddress',
      key: 'ipAddress',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Last Active',
      dataIndex: 'lastActive',
      key: 'lastActive',
      render: (_, data) => <div>{dayjs(data?.lastActive).format('DD/MM/YYYY')}</div>,
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: (_, data) => (
        <>
          <Tag color="green">{data.platform === '1' ? 'Android' : data.platform === '2' ? 'Ios' : 'Both'}</Tag>
        </>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get('/user');
      if (response?.data?.data && response.data.success) {
        setLoading(false);
        setRows(response.data.data);
      } else {
        setLoading(false);
        toast.error(response?.data?.message || 'Something Went Wrong');
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message || 'Something Went Wrong');
    }
  };

  useEffect(() => {
    void fetchData();
  }, []);

  const handleCancel = () => {
    setVisble(false);
  };

  const handleDetails = (values: any) => {
    setUserLogs(values?.UserLogs);
    setVisble(true);
  };

  return (
    <div className="bg-white px-6 py-6">
      <div className="d-flex justify-space-between mb-4 align-center main-div-class">
        <div>
          <h3 className="font-weight-600 font-size-18 line-height-22 mb-0">Users List</h3>
        </div>
      </div>
      <div>
        <Table columns={columns} dataSource={rows} rowKey={(data: any) => data?.id} />
      </div>
      <Drawer
        title={
          <div className="d-flex justify-space-between align-center">
            <div style={{color: '#fff'}}>Users Details</div>
            <div>
              <Button onClick={handleCancel} icon={<CloseOutlined />}></Button>
            </div>
          </div>
        }
        placement={'right'}
        onClose={handleCancel}
        open={visible}
        width={1000}
        key={'Right'}>
        <Row>
          <Col span={24}>
            <Table
              columns={logColumns}
              dataSource={userLogs}
              rowKey={(data: any) => data?.id}
              size="middle"
              style={{
                width: '100% !important',
              }}
            />
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};

export default Users;
