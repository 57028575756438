// context/todoContext.tsx
import * as React from 'react';

interface IContextType {
  loading: boolean;
  setLoading: (value: boolean) => void;
}

interface IProps {
  children: React.ReactNode;
}

export const LoadingContext = React.createContext<IContextType>({loading: false, setLoading: () => undefined});

const LoadingProvider: React.FC<IProps> = ({children}: IProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  return <LoadingContext.Provider value={{loading, setLoading}}>{children}</LoadingContext.Provider>;
};

export default LoadingProvider;
