import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Input, Row, Switch} from 'antd';
import {BASEURL} from '../Constant/Constant';
import {toast} from 'react-hot-toast';
import {LoadingContext} from '../Context/LoadingContext';
import {useNavigate, useParams} from 'react-router-dom';
import axiosClient from '../Constant/axiossetup';
import {pickBy} from 'lodash';
import TextArea from 'antd/es/input/TextArea';

const UpdateAppDetails = () => {
  const [form] = Form.useForm();
  const {packagename} = useParams();
  const {setLoading} = useContext(LoadingContext);

  const [IsAppUpdated, setAppUpdated] = useState(true);
  const [IsAppRedirect, setIsAppRedirect] = useState(false);
  const [settingId, setSettingId] = useState('');

  const navigate = useNavigate();

  const updateAdsData = async (object: any) => {
    try {
      const response = await axiosClient.post('/applist/setting', {
        ...object,
      });
      if (response?.data && response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response?.data?.message || 'Something Went Wrong');
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Something Went Wrong');
    }
  };

  const handleOk = (values: any) => {
    const cleanedObject = pickBy(values, ele => ele !== undefined);
    const obj = {
      ...cleanedObject,
      settingId,
    };
    void updateAdsData(obj);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get(`${BASEURL}/applist/${String(packagename)}`);
      if (response?.data && response.data.success) {
        setLoading(false);
        toast.success(response?.data?.message);
        setSettingId(response?.data?.data?.id);
        const updated = response?.data?.data?.AppSetting?.isUpdate;
        if (updated !== undefined) {
          setAppUpdated(updated);
        }
        const redirect = response?.data?.data?.AppSetting?.isRedirect;
        if (redirect !== undefined) {
          setIsAppRedirect(redirect);
        }
        form.setFieldsValue({
          aboutUsUrl: response?.data?.data?.AppSetting?.aboutUsUrl,
          appName: response?.data?.data?.AppSetting?.appName,
          appNote: response?.data?.data?.AppSetting?.appNote,
          appVersion: response?.data?.data?.AppSetting?.appVersion,
          //   extraPayload: response?.data?.data?.AppSetting?.extraPayload,
          isRedirect: response?.data?.data?.AppSetting?.isRedirect || IsAppRedirect,
          isUpdate: response?.data?.data?.AppSetting?.isUpdate || IsAppUpdated,
          privacyPolicyUrl: response?.data?.data?.AppSetting?.privacyPolicyUrl,
          redirectUrl: response?.data?.data?.AppSetting?.redirectUrl,
          termConditionUrl: response?.data?.data?.AppSetting?.termConditionUrl,
        });
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message || 'Something Went Wrong');
    }
  };

  useEffect(() => {
    void fetchData();
  }, []);

  const onAppUpdateChange = (checked: boolean) => {
    setAppUpdated(checked);
  };

  const onAppRedirectChange = (checked: boolean) => {
    setIsAppRedirect(checked);
  };

  return (
    <div>
      <Row justify={'center'}>
        <Col span={24}>
          <Card title="Update Basic Details">
            <Form name="setting" form={form} onFinish={handleOk} layout="vertical" autoComplete="off">
              <Row gutter={[60, 30]}>
                <Col span={8}>
                  <Form.Item name="isUpdate" label="App Update" initialValue={IsAppUpdated}>
                    <Switch checked={IsAppUpdated} onChange={onAppUpdateChange} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="isRedirect" label="App Redirect" initialValue={IsAppRedirect}>
                    <Switch checked={IsAppRedirect} onChange={onAppRedirectChange} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="appName"
                    label="App Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter App Name',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="appVersion"
                    label="App Version"
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter App Version',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="redirectUrl"
                    label="App Redirect URL"
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Redirect URL',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="privacyPolicyUrl" label="Privacy Policy">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="termConditionUrl" label="Term & Condition">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="aboutUsUrl" label="About Us">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="appNote" label="App Note">
                    <TextArea rows={6} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Button type="primary" htmlType="submit" className="mt-3">
                  Update Now
                </Button>
                <Button
                  htmlType="submit"
                  className="mt-3 mx-5"
                  onClick={() => {
                    navigate('/applist');
                  }}>
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateAppDetails;
