import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {MenuArray} from '../../Constant/MainMenu';
import {LogoutOutlined} from '@ant-design/icons';

function Sidebar() {
  const location = useLocation();

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  return (
    <>
      <div className="side-drawer">
        <div className="sidebar-top-icon text-white font-size-24">Admin</div>
        <div className="sidebar-scroll">
          <ul>
            {MenuArray.map((item, idx) => {
              return (
                <li key={idx} className={`${location.pathname.includes(item.url) ? 'custom-menu-item-selected' : ''}`}>
                  <div className="sidebar">
                    <Link to={item.url} className="d-flex gap-1">
                      <div className="side-drawer-icon d-flex align-items-center">{item.file}</div>
                      <div className="sidebar-menu-name">{item.name}</div>
                    </Link>
                  </div>
                </li>
              );
            })}
            <li>
              <div className="sidebar" style={{cursor: 'pointer'}}>
                <div className="d-flex gap-1" onClick={handleLogout}>
                  <div className="side-drawer-icon d-flex align-items-center">
                    <LogoutOutlined />
                  </div>
                  <div className="sidebar-menu-name">Logout</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
