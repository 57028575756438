import React from 'react';
import nodatafound from '../assets/img/notfound.jpg';

const NotFound = () => {
  return (
    <div style={{height: '100vh'}} className="d-flex justify-center align-center">
      <img src={nodatafound} alt="notfound" className="w-50" />
    </div>
  );
};

export default NotFound;
