import React from 'react';

const Dashboard = () => {
  // const [dashboardData] = useState({
  //   city: 0,
  //   surname: 0,
  //   height: 0,
  //   weight: 0,
  //   designation: 0,
  //   education: 0,
  //   income: 0,
  //   martial: 0,
  //   users: 0,
  // });

  return (
    <div className="bg-white px-6 py-6">
      <div className="d-flex justify-space-between mb-4 align-center main-div-class">
        <div>
          <h3 className="font-weight-600 font-size-18 line-height-22 mb-0">Dashboard</h3>
        </div>
      </div>
      {/* <div>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Card>
              <div className="d-flex justify-space-between">
                <div>
                  <h2 className="dashbord-title">
                    <Link to={'/city'}>City</Link>
                  </h2>
                  <h1 className="dashboard-subtitle">{dashboardData.city}</h1>
                </div>
                <div className="dahboard-icon">
                  <BankOutlined />
                </div>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <div className="d-flex justify-space-between">
                <div>
                  <h2 className="dashbord-title">
                    <Link to={'/education'}>Education</Link>
                  </h2>
                  <h1 className="dashboard-subtitle">{dashboardData.education}</h1>
                </div>
                <div className="dahboard-icon">
                  <ReadOutlined />
                </div>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <div className="d-flex justify-space-between">
                <div>
                  <h2 className="dashbord-title">
                    <Link to={'/surname'}>Surname</Link>
                  </h2>
                  <h1 className="dashboard-subtitle">{dashboardData.surname}</h1>
                </div>
                <div className="dahboard-icon">
                  <ReadOutlined />
                </div>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <div className="d-flex justify-space-between">
                <div>
                  <h2 className="dashbord-title">
                    <Link to={'/height'}>Height</Link>
                  </h2>
                  <h1 className="dashboard-subtitle">{dashboardData.height}</h1>
                </div>
                <div className="dahboard-icon">
                  <CodeSandboxOutlined />
                </div>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <div className="d-flex justify-space-between">
                <div>
                  <h2 className="dashbord-title">
                    <Link to={'/weight'}>Weight</Link>
                  </h2>
                  <h1 className="dashboard-subtitle">{dashboardData.weight}</h1>
                </div>
                <div className="dahboard-icon">
                  <CodeSandboxOutlined />
                </div>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <div className="d-flex justify-space-between">
                <div>
                  <h2 className="dashbord-title">
                    <Link to={'/income'}>Income</Link>
                  </h2>
                  <h1 className="dashboard-subtitle">{dashboardData.income}</h1>
                </div>
                <div className="dahboard-icon">
                  <SolutionOutlined />
                </div>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <div className="d-flex justify-space-between">
                <div>
                  <h2 className="dashbord-title">
                    <Link to={'/martial'}>Martial Status</Link>
                  </h2>
                  <h1 className="dashboard-subtitle">{dashboardData.martial}</h1>
                </div>
                <div className="dahboard-icon">
                  <SolutionOutlined />
                </div>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <div className="d-flex justify-space-between">
                <div>
                  <h2 className="dashbord-title">
                    <Link to={'/designation'}>Designation</Link>
                  </h2>
                  <h1 className="dashboard-subtitle">{dashboardData.designation}</h1>
                </div>
                <div className="dahboard-icon">
                  <SolutionOutlined />
                </div>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <div className="d-flex justify-space-between">
                <div>
                  <h2 className="dashbord-title">
                    <Link to={'/users'}>Users</Link>
                  </h2>
                  <h1 className="dashboard-subtitle">{dashboardData.users}</h1>
                </div>
                <div className="dahboard-icon">
                  <TeamOutlined />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div> */}
    </div>
  );
};

export default Dashboard;
