import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Input, Row, Select, Switch} from 'antd';
import {BASEURL} from '../Constant/Constant';
import {toast} from 'react-hot-toast';
import {LoadingContext} from '../Context/LoadingContext';
import {useNavigate, useParams} from 'react-router-dom';
import axiosClient from '../Constant/axiossetup';
import {pickBy} from 'lodash';

const UpdateAppAds = () => {
  const [form] = Form.useForm();
  const {packagename} = useParams();
  const {setLoading} = useContext(LoadingContext);

  const [showAds, setShowAds] = useState(true);
  const [testModeAds, setTestModeAds] = useState(false);
  const [settingId, setSettingId] = useState('');
  const navigate = useNavigate();

  const updateAdsData = async (object: any) => {
    try {
      const response = await axiosClient.post('/applist/ads', {
        ...object,
      });

      if (response?.data && response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response?.data?.message || 'Something Went Wrong');
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Something Went Wrong');
    }
  };

  const handleOk = (values: any) => {
    const cleanedObject = pickBy(values, ele => ele !== undefined);
    const obj = {
      ...cleanedObject,
      settingId,
    };
    void updateAdsData(obj);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get(`${BASEURL}/applist/${String(packagename)}`);
      if (response?.data && response.data.success) {
        setLoading(false);
        toast.success(response?.data?.message);
        setSettingId(response?.data?.data?.id);

        const showAdsData = response?.data?.data?.AppAd?.showAd;
        if (showAdsData !== undefined) {
          setShowAds(showAdsData);
        }
        const testMode = response?.data?.data?.AppAd?.adTestMode;
        if (testMode !== undefined) {
          setTestModeAds(testMode);
        }

        form.setFieldsValue({
          showAd: response?.data?.data?.AppAd?.showAd || showAds,
          adTestMode: response?.data?.data?.AppAd?.adTestMode || testModeAds,
          backPressAdType: response?.data?.data?.AppAd?.backPressAdType,
          splashAdType: response?.data?.data?.AppAd?.splashAdType,
          adHomeClickCount: response?.data?.data?.AppAd?.adHomeClickCount,
          adInnerClickCount: response?.data?.data?.AppAd?.adInnerClickCount,
          adBackButtonCount: response?.data?.data?.AppAd?.adBackButtonCount,
        });
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message || 'Something Went Wrong');
    }
  };

  useEffect(() => {
    void fetchData();
  }, []);

  const onShowAdChange = (checked: boolean) => {
    setShowAds(checked);
  };

  const onChangeTestAd = (checked: boolean) => {
    setTestModeAds(checked);
  };

  return (
    <div>
      <Row justify={'center'}>
        <Col span={24}>
          <Card title={'Update Ads Setting'}>
            <Form name="setting" form={form} onFinish={handleOk} layout="vertical" autoComplete="off">
              <Row gutter={[60, 30]}>
                <Col span={8}>
                  <Form.Item name="showAd" label="Show Ads" initialValue={showAds}>
                    <Switch checked={showAds} onChange={onShowAdChange} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="adTestMode" label="Test Mode" initialValue={testModeAds}>
                    <Switch checked={testModeAds} onChange={onChangeTestAd} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="adHomeClickCount"
                    label="Home Ads Click"
                    initialValue={1}
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Value',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="adInnerClickCount"
                    label="Inner Page Ads Click"
                    initialValue={1}
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Value',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="adBackButtonCount"
                    label="Back Button Ads Click"
                    initialValue={1}
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Value',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="splashAdType"
                    label="Splash Screen Ads Type"
                    rules={[
                      {
                        required: true,
                        message: 'Select Option!',
                      },
                    ]}>
                    <Select
                      placeholder="Select Ads Type"
                      style={{width: '100%'}}
                      options={[
                        {value: '1', label: 'AppOpen'},
                        {value: '2', label: 'Interstital'},
                        {value: '3', label: 'Mix'},
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="backPressAdType"
                    label="Back Press Ads Type"
                    rules={[
                      {
                        required: true,
                        message: 'Select Option!',
                      },
                    ]}>
                    <Select
                      placeholder="Select Ads Type"
                      style={{width: '100%'}}
                      options={[
                        {value: '1', label: 'AppOpen'},
                        {value: '2', label: 'Interstital'},
                        {value: '3', label: 'Mix'},
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="mt-3">
                  Update Now
                </Button>
                <Button
                  htmlType="submit"
                  className="mt-3 mx-5"
                  onClick={() => {
                    navigate('/applist');
                  }}>
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateAppAds;
