import {useEffect, useState} from 'react';
import {Layout, Tooltip, Button} from 'antd';
import {LogoutOutlined, NotificationOutlined} from '@ant-design/icons';

const {Header} = Layout;

const TopHeader = (props: any) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => {
      window.removeEventListener('resize', updateMedia);
    };
  });

  return (
    <div>
      <Header className={`${isDesktop ? 'top-header' : 'top-header-hide'}`}>
        <div className="d-flex logo">
          <div className="d-flex justify-space-between w-100">
            <div className="d-flex justify-space-between">
              <div className="menu-none">
                <Button
                  onClick={() => {
                    props.setVisible(true);
                  }}
                  className="common-button">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.33398 5H16.6673M3.33398 10H16.6673M3.33398 15H16.6673"
                      stroke="#73768C"
                      strokeWidth="1.5"
                    />
                  </svg>
                </Button>
              </div>
            </div>
            <div className="menu">
              <ul className="main-menu">
                <li className="menu-item">
                  <div className="d-flex align-items-center">
                    <span className="main-menu-icon">
                      <Tooltip placement="bottom" title={'Logout'}>
                        <LogoutOutlined />
                      </Tooltip>
                    </span>
                  </div>
                </li>
                <li className="menu-item">
                  <div className="d-flex align-items-center">
                    <span className="main-menu-icon">
                      <Tooltip placement="bottom" title={'Notification'}>
                        <NotificationOutlined />
                      </Tooltip>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Header>
    </div>
  );
};

export default TopHeader;
