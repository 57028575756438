import {AppstoreOutlined, TeamOutlined, AppstoreAddOutlined} from '@ant-design/icons';

export const MenuArrayDrawer = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    message: 'Dashboard',
    file: <AppstoreOutlined />,
  },
  {
    name: 'App List',
    url: '/applist',
    message: 'App List',
    file: <AppstoreAddOutlined />,
  },
  {
    name: 'Users',
    url: '/users',
    message: 'Users',
    file: <TeamOutlined />,
  },
  // {
  //   name: 'Setting',
  //   url: '/setting',
  //   message: 'Setting',
  //   file: <SettingOutlined />,
  // },
];
