import {Spin} from 'antd';
import React from 'react';

const MainLoader = () => {
  return (
    <div className="main-loader">
      <Spin tip="Loading" size="large"></Spin>
    </div>
  );
};

export default MainLoader;
