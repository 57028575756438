import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Input, Row, Switch} from 'antd';
import {BASEURL} from '../Constant/Constant';
import {toast} from 'react-hot-toast';
import {LoadingContext} from '../Context/LoadingContext';
import {useNavigate, useParams} from 'react-router-dom';
import axiosClient from '../Constant/axiossetup';
import {pickBy} from 'lodash';

const UpdateFacebookAds = () => {
  const [form] = Form.useForm();
  const {packagename} = useParams();
  const {setLoading} = useContext(LoadingContext);

  const [IsActiveAppOpen, setIsActiveAppOpen] = useState(true);
  const [IsActiveAdaptiveBanner, setIsActiveAdaptiveBanner] = useState(true);
  const [IsActiveBanner, setIsActiveBanner] = useState(true);
  const [IsActiveInterstitial, setIsActiveInterstitial] = useState(true);
  const [IsActiveInterstitialVideo, setIsActiveInterstitialVideo] = useState(true);
  const [IsActiveRewarded, setIsActiveRewarded] = useState(true);
  const [IsActiveRewardedInterstitial, setIsActiveRewardedInterstitial] = useState(true);
  const [IsActiveNativeAdvanced, setIsActiveNativeAdvanced] = useState(true);
  const [IsActiveNativeAdvancedVideo, setIsActiveNativeAdvancedVideo] = useState(true);

  const [settingId, setSettingId] = useState('');
  const navigate = useNavigate();

  const updateAdsData = async (object: any) => {
    try {
      const response = await axiosClient.post('/applist/facebook', {
        ...object,
      });

      if (response?.data && response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response?.data?.message || 'Something Went Wrong');
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Something Went Wrong');
    }
  };

  const handleOk = (values: any) => {
    const cleanedObject = pickBy(values, ele => ele !== undefined);
    const obj = {
      ...cleanedObject,
      settingId,
    };
    void updateAdsData(obj);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get(`${BASEURL}/applist/${String(packagename)}`);
      if (response?.data && response.data.success) {
        setLoading(false);
        toast.success(response?.data?.message);
        setSettingId(response?.data?.data?.id);

        const isFbAdaptiveBanner = response?.data?.data?.FacebookAds[0]?.isFbAdaptiveBanner;
        if (isFbAdaptiveBanner !== undefined) {
          setIsActiveAdaptiveBanner(isFbAdaptiveBanner);
        }

        const isFbAppOpen = response?.data?.data?.FacebookAds[0]?.isFbAppOpen;
        if (isFbAppOpen !== undefined) {
          setIsActiveAppOpen(isFbAppOpen);
        }

        const isFbBanner = response?.data?.data?.FacebookAds[0]?.isFbBanner;
        if (isFbBanner !== undefined) {
          setIsActiveBanner(isFbBanner);
        }

        const isFbInterstitial = response?.data?.data?.FacebookAds[0]?.isFbInterstitial;
        if (isFbInterstitial !== undefined) {
          setIsActiveInterstitial(isFbInterstitial);
        }

        const isFbInterstitialVideo = response?.data?.data?.FacebookAds[0]?.isFbInterstitialVideo;
        if (isFbInterstitialVideo !== undefined) {
          setIsActiveInterstitialVideo(isFbInterstitialVideo);
        }

        const isFbNativeAdvanced = response?.data?.data?.FacebookAds[0]?.isFbNativeAdvanced;
        if (isFbNativeAdvanced !== undefined) {
          setIsActiveNativeAdvanced(isFbNativeAdvanced);
        }

        const isFbNativeAdvancedVideo = response?.data?.data?.FacebookAds[0]?.isFbNativeAdvancedVideo;
        if (isFbNativeAdvancedVideo !== undefined) {
          setIsActiveNativeAdvancedVideo(isFbNativeAdvancedVideo);
        }

        const isFbRewarded = response?.data?.data?.FacebookAds[0]?.isFbRewarded;
        if (isFbRewarded !== undefined) {
          setIsActiveRewarded(isFbRewarded);
        }

        const isFbRewardedInterstitial = response?.data?.data?.FacebookAds[0]?.isFbRewardedInterstitial;
        if (isFbRewardedInterstitial !== undefined) {
          setIsActiveRewardedInterstitial(isFbRewardedInterstitial);
        }

        form.setFieldsValue({
          fbAdaptiveBanner: response?.data?.data?.FacebookAds[0]?.fbAdaptiveBanner,
          fbAppOpen: response?.data?.data?.FacebookAds[0]?.fbAppOpen,
          fbBanner: response?.data?.data?.FacebookAds[0]?.fbBanner,
          fbInterstitial: response?.data?.data?.FacebookAds[0]?.fbInterstitial,
          fbInterstitialVideo: response?.data?.data?.FacebookAds[0]?.fbInterstitialVideo,
          fbNativeAdvanced: response?.data?.data?.FacebookAds[0]?.fbNativeAdvanced,
          fbNativeAdvancedVideo: response?.data?.data?.FacebookAds[0]?.fbNativeAdvancedVideo,
          fbRewarded: response?.data?.data?.FacebookAds[0]?.fbRewarded,
          fbRewardedInterstitial: response?.data?.data?.FacebookAds[0]?.fbRewardedInterstitial,
          isFbAppOpen: response?.data?.data?.FacebookAds[0]?.isFbAppOpen || IsActiveAppOpen,
          isFbAdaptiveBanner: response?.data?.data?.FacebookAds[0]?.isFbAdaptiveBanner || IsActiveAdaptiveBanner,
          isFbBanner: response?.data?.data?.FacebookAds[0]?.isFbBanner || IsActiveBanner,
          isFbInterstitial: response?.data?.data?.FacebookAds[0]?.isFbInterstitial || IsActiveInterstitial,
          isFbInterstitialVideo:
            response?.data?.data?.FacebookAds[0]?.isFbInterstitialVideo || IsActiveInterstitialVideo,
          isFbRewarded: response?.data?.data?.FacebookAds[0]?.isFbRewarded || IsActiveRewarded,
          isFbRewardedInterstitial:
            response?.data?.data?.FacebookAds[0]?.isFbRewardedInterstitial || IsActiveRewardedInterstitial,
          isFbNativeAdvanced: response?.data?.data?.FacebookAds[0]?.isFbNativeAdvanced || IsActiveNativeAdvanced,
          isFbNativeAdvancedVideo:
            response?.data?.data?.FacebookAds[0]?.isFbNativeAdvancedVideo || IsActiveNativeAdvancedVideo,
        });
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message || 'Something Went Wrong');
    }
  };

  useEffect(() => {
    void fetchData();
  }, []);

  return (
    <div>
      <Row justify={'center'}>
        <Col span={24}>
          <Card title={'Update Ads'}>
            <Form name="setting" form={form} onFinish={handleOk} layout="vertical" autoComplete="off">
              <Row gutter={[60, 30]}>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="fbAppOpen" label="Facebook App Open">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="isFbAppOpen" label="Active" initialValue={IsActiveAppOpen}>
                        <Switch
                          checked={IsActiveAppOpen}
                          onChange={e => {
                            setIsActiveAppOpen(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="fbAdaptiveBanner" label="Facebook  Adaptive Banner">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="isFbAdaptiveBanner" label="Active" initialValue={IsActiveAdaptiveBanner}>
                        <Switch
                          checked={IsActiveAdaptiveBanner}
                          onChange={e => {
                            setIsActiveAdaptiveBanner(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="fbBanner" label="Facebook Banner">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="isFbBanner" label="Active" initialValue={IsActiveBanner}>
                        <Switch
                          checked={IsActiveBanner}
                          onChange={e => {
                            setIsActiveBanner(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="fbInterstitial" label="Facebook Interstitial">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="isFbInterstitial" label="Active" initialValue={IsActiveInterstitial}>
                        <Switch
                          checked={IsActiveInterstitial}
                          onChange={e => {
                            setIsActiveInterstitial(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="fbInterstitialVideo" label="Facebook Interstitial Video">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="isFbInterstitialVideo" label="Active" initialValue={IsActiveInterstitialVideo}>
                        <Switch
                          checked={IsActiveInterstitialVideo}
                          onChange={e => {
                            setIsActiveInterstitialVideo(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="fbRewarded" label="Facebook Rewarded">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="isFbRewarded" label="Active" initialValue={IsActiveRewarded}>
                        <Switch
                          checked={IsActiveRewarded}
                          onChange={e => {
                            setIsActiveRewarded(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="fbRewardedInterstitial" label="Facebook Rewarded Interstitial">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="isFbRewardedInterstitial"
                        label="Active"
                        initialValue={IsActiveRewardedInterstitial}>
                        <Switch
                          checked={IsActiveRewardedInterstitial}
                          onChange={e => {
                            setIsActiveRewardedInterstitial(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="fbNativeAdvanced" label="Facebook Native Advanced">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="isFbNativeAdvanced" label="Active" initialValue={IsActiveNativeAdvanced}>
                        <Switch
                          checked={IsActiveNativeAdvanced}
                          onChange={e => {
                            setIsActiveNativeAdvanced(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[30, 20]}>
                    <Col span={18}>
                      <Form.Item name="fbNativeAdvancedVideo" label="Facebook Native Advanced Video">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="isFbNativeAdvancedVideo"
                        label="Active"
                        initialValue={IsActiveNativeAdvancedVideo}>
                        <Switch
                          checked={IsActiveNativeAdvancedVideo}
                          onChange={e => {
                            setIsActiveNativeAdvancedVideo(e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="mt-3">
                  Update Now
                </Button>
                <Button
                  htmlType="submit"
                  className="mt-3 mx-5"
                  onClick={() => {
                    navigate('/applist');
                  }}>
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateFacebookAds;
