import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Select, Table, Tag} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import axiosClient from '../Constant/axiossetup';
import {toast} from 'react-hot-toast';
import {DeleteOutlined, FormOutlined, PlusOutlined, SettingOutlined, SlackSquareOutlined} from '@ant-design/icons';
import {LoadingContext} from '../Context/LoadingContext';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';

interface DataType {
  AppAd: object;
  AppSetting: object;
  createdAt: string;
  deletedAt: null;
  id: string;
  packageName: string;
  platform: string;
  status: string;
  isAppRejected: boolean;
  updatedAt: string;
}

const AppList = () => {
  const [visible, setVisble] = useState(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [updateId, setUpdateId] = useState<string>('');
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(1);
  // const [paginationSize, setPaginationSize] = useState(10);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const {setLoading} = useContext(LoadingContext);

  const columns: ColumnsType<DataType> = [
    {
      title: 'Id',
      dataIndex: 'index',
      key: 'id',
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: 'Package Name',
      dataIndex: 'packageName',
      key: 'packageName',
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: (_, data) => (
        <>
          <Tag color="green">{data.platform === '1' ? 'Android' : data.platform === '2' ? 'Ios' : 'Both'}</Tag>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, data) => (
        <>
          <Tag color={data.status === 'active' ? 'green' : 'red'}>
            {data.status === 'active' ? 'Active' : 'Inactive'}
          </Tag>
        </>
      ),
    },
    {
      title: 'App Rejected',
      dataIndex: 'isAppRejected',
      key: 'isAppRejected',
      render: (_, data) => {
        return (
          <>
            <Tag color={data?.isAppRejected ? 'red' : 'green'}>{data.isAppRejected ? 'Rejected' : 'Active'}</Tag>
          </>
        );
      },
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, data) => <div>{dayjs(data?.createdAt).format('DD/MM/YYYY')}</div>,
    },
    {
      title: 'Update',
      key: 'update',
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            className="mx-1"
            icon={<FormOutlined />}
            onClick={() => {
              handleUpdate(record);
            }}>
            Update
          </Button>
        </div>
      ),
    },
    {
      title: 'Setting',
      key: 'setting',
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            className="mx-1"
            icon={<SettingOutlined />}
            onClick={() => {
              navigate(`/appbasic/${record.packageName}`);
            }}>
            Update Setting
          </Button>
        </div>
      ),
    },
    {
      title: 'Ads',
      key: 'ads',
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            className="mx-1"
            icon={<SlackSquareOutlined />}
            onClick={() => {
              navigate(`/appads/${record?.packageName}`);
            }}>
            Update Ads
          </Button>
        </div>
      ),
    },
    {
      title: 'Facebbok Ads',
      key: 'facebook',
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            className="mx-1"
            icon={<FormOutlined />}
            onClick={() => {
              navigate(`/facebook/${record?.packageName}`);
            }}>
            Update Facebook Ads
          </Button>
        </div>
      ),
    },
    {
      title: 'Google Ads',
      key: 'googleads',
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            className="mx-1"
            icon={<FormOutlined />}
            onClick={() => {
              navigate(`/google/${record?.packageName}`);
            }}>
            Update Google Ads
          </Button>
        </div>
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      render: (_, record) => (
        <div>
          <Button
            danger
            className="mx-1"
            icon={<DeleteOutlined />}
            onClick={() => {
              void handleDeleteData(record);
            }}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get('/applist');
      if (response?.data?.data && response.data.success) {
        setLoading(false);
        setRows(response.data.data);
      } else {
        setLoading(false);
        toast.error(response?.data?.message || 'Something Went Wrong');
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message || 'Something Went Wrong');
    }
  };

  const handleDeleteData = async (params: any) => {
    try {
      setLoading(true);
      const response = await axiosClient.delete(`/applist/${String(params.id)}`);
      if (response?.data?.data && response.data.success) {
        setLoading(false);
        void fetchData();
      } else {
        setLoading(false);
        toast.error(response?.data?.message || 'Something Went Wrong');
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message || 'Something Went Wrong');
    }
  };

  useEffect(() => {
    void fetchData();
  }, []);

  const handleCancel = () => {
    setVisble(false);
    setIsUpdated(false);
    setUpdateId('');
    form.resetFields();
  };

  const handleUpdate = (values: any) => {
    setIsUpdated(true);
    setUpdateId(values.id);
    form.setFieldsValue({
      packageName: String(values?.packageName).trim(),
      platform: values.platform,
      status: values.status === 'active',
      isAppRejected: values.isAppRejected,
    });
    setVisble(true);
  };

  const handleOk = async (values: any) => {
    try {
      let response;
      if (!isUpdated) {
        response = await axiosClient.post('/applist', {
          packageName: String(values?.packageName).trim(),
          platform: values.platform,
          status: values.status,
          isAppRejected: values.isAppRejected,
        });
      } else {
        response = await axiosClient.put(`/applist/${updateId}`, {
          packageName: String(values?.packageName).trim(),
          platform: values.platform,
          status: values.status,
          isAppRejected: values.isAppRejected,
        });
      }
      if (response?.data && response.data.success) {
        toast.success(response.data.message);
        form.resetFields();
        setVisble(false);
        setIsUpdated(false);
        setUpdateId('');
        void fetchData();
      } else {
        toast.error(response?.data?.message || 'Something Went Wrong');
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Something Went Wrong');
    }
  };

  return (
    <div className="bg-white px-6 py-6">
      <div className="d-flex justify-space-between mb-4 align-center main-div-class">
        <div>
          <h3 className="font-weight-600 font-size-18 line-height-22 mb-0">App List</h3>
        </div>
        <div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setVisble(true);
            }}>
            Add App
          </Button>
        </div>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={rows}
          rowKey={(data: any) => data?.id}
          scroll={{x: true}}
          pagination={{
            onChange(current, pageSize) {
              setPage(current);
              // setPaginationSize(pageSize);
            },
            defaultPageSize: 10,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
        />
      </div>
      <Modal
        title={!isUpdated ? 'Add App' : 'Update App'}
        open={visible}
        onCancel={handleCancel}
        footer={[
          <div key={'delete'}>
            <Button type="primary" onClick={form.submit}>
              Ok
            </Button>
            <Button danger onClick={handleCancel}>
              Cancel
            </Button>
          </div>,
        ]}>
        <Form
          form={form}
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          autoComplete="off"
          onFinish={(value: any) => {
            void handleOk(value);
          }}>
          <Form.Item
            label="Package Name"
            name="packageName"
            rules={[
              {
                required: true,
                message: 'Please Enter Package Name!',
                type: 'string',
              },
            ]}>
            <Input placeholder="Enter Package Name" />
          </Form.Item>
          <Form.Item
            name="platform"
            label="Platform"
            rules={[
              {
                required: true,
                message: 'Select Platform!',
              },
            ]}>
            <Select
              placeholder="Select Platform"
              style={{width: '100%'}}
              options={[
                {value: '1', label: 'Android'},
                {value: '2', label: 'Ios'},
                {value: '4', label: 'Both'},
              ]}
            />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
                message: 'Select Status!',
              },
            ]}>
            <Select
              placeholder="Select Status"
              style={{width: '100%'}}
              options={[
                {value: true, label: 'Active'},
                {value: false, label: 'Inactive'},
              ]}
            />
          </Form.Item>
          <Form.Item
            name="isAppRejected"
            label="App Rejected"
            rules={[
              {
                required: true,
                message: 'Select Reject Type!',
              },
            ]}>
            <Select
              placeholder="Select Reject Type"
              style={{width: '100%'}}
              options={[
                {value: false, label: 'Not Rejected'},
                {value: true, label: 'Rejected'},
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AppList;
