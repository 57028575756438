import axios from 'axios';
import {BASEURL} from './Constant';

// //Create instance of axios
const axiosClient = axios.create({
  baseURL: BASEURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Create a request interceptor for my instance and get accessToken on the fly
axiosClient.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('usertoken');
    config.timeout = 4000;
    config.headers['x-token'] = `JWT  ${String(token)}`;
    return config;
  },
  async error => {
    return await Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const res = error.response;
    if (res.data.code === 401 && res.data.status === 'failed') {
      window.location.href = '/login';
    }
    return await Promise.reject(error);
  },
);

export default axiosClient;
