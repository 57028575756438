import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Button, Drawer} from 'antd';
import {MenuArrayDrawer} from '../../Constant/DrawerMenu';
import {CloseOutlined, LogoutOutlined} from '@ant-design/icons';

interface Props {
  setVisible: (data: boolean) => void;
  visible: boolean;
}

const SideDrawer = ({setVisible, visible}: Props) => {
  const location = useLocation();

  const handleClose = () => {
    setVisible(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  return (
    <>
      <Drawer
        className="ant-head"
        title={
          <div className="d-flex justify-space-between align-center">
            <div className="dashboard-logo text-white text-white font-size-24">Admin</div>
            <Button className="drawer-btn common-button" onClick={handleClose}>
              <CloseOutlined />
            </Button>
          </div>
        }
        placement="left"
        width={300}
        open={visible}>
        <div className="drawer-header px-3 py-3">
          <ul className="drawer-ul">
            {MenuArrayDrawer.map((item, idx) => {
              return (
                <li key={idx} className={`${location.pathname.includes(item.url) ? 'custom-menu-item-selected' : ''}`}>
                  <div className="sidebar">
                    <Link to={item.url} className="d-flex gap-1">
                      <div className="side-drawer-icon d-flex align-items-center">{item.file}</div>
                      <div className="drawer-sidebar-menu-name">{item.name}</div>
                    </Link>
                  </div>
                </li>
              );
            })}
            <li>
              <div className="sidebar" style={{cursor: 'pointer'}}>
                <div className="d-flex gap-1" onClick={handleLogout}>
                  <div className="side-drawer-icon d-flex align-items-center">
                    <LogoutOutlined />
                  </div>
                  <div className="sidebar-menu-name">Logout</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </Drawer>
    </>
  );
};

export default SideDrawer;
