import React, {Suspense, useContext} from 'react';
import DashboardLayout from './Component/Layout/DashboardLayout';
import {Navigate, Route, Routes} from 'react-router-dom';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import MainLoader from './Component/Loader/MainLoader';
import AppList from './Pages/AppList';
import {LoadingContext} from './Context/LoadingContext';
import Users from './Pages/Users';
// import Setting from './Pages/Setting';
import Protected from './Routes/ProtectedRoutes';
import NotFound from './Pages/NotFound';
import UpdateAppDetails from './Pages/UpdateAppDetails';
import UpdateAppAds from './Pages/UpdateAppAds';
import UpdatGoogleAds from './Pages/UpdatGoogleAds';
import UpdateFacebookAds from './Pages/UpdateFacebookAds';

const App = () => {
  const {loading} = useContext(LoadingContext);

  return (
    <div>
      {loading && <MainLoader />}
      <Suspense fallback={<MainLoader />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <Protected>
                <DashboardLayout />
              </Protected>
            }>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route
              path="/dashboard"
              element={
                <Protected>
                  <Dashboard />
                </Protected>
              }
            />
            <Route
              path="/applist"
              element={
                <Protected>
                  <AppList />
                </Protected>
              }
            />
            <Route
              path="/applist"
              element={
                <Protected>
                  <AppList />
                </Protected>
              }
            />
            <Route
              path="/appbasic/:packagename"
              element={
                <Protected>
                  <UpdateAppDetails />
                </Protected>
              }
            />
            <Route
              path="/appads/:packagename"
              element={
                <Protected>
                  <UpdateAppAds />
                </Protected>
              }
            />
            <Route
              path="/google/:packagename"
              element={
                <Protected>
                  <UpdatGoogleAds />
                </Protected>
              }
            />
            <Route
              path="/facebook/:packagename"
              element={
                <Protected>
                  <UpdateFacebookAds />
                </Protected>
              }
            />
            <Route
              path="/users"
              element={
                <Protected>
                  <Users />
                </Protected>
              }
            />
            {/* <Route
              path="/setting"
              element={
                <Protected>
                  <Setting />
                </Protected>
              }
            /> */}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
