import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main.scss';
import 'antd/dist/reset.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import {Toaster} from 'react-hot-toast';
import LoadingProvider from './Context/LoadingContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <LoadingProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0a7ea4',
            fontFamily: 'Figtree, sans-serif',
          },
        }}>
        <BrowserRouter>
          <App />
          <Toaster position="top-right" />
        </BrowserRouter>
      </ConfigProvider>
    </LoadingProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
