import React from 'react';
import {Col, Row, Form, Input, Button} from 'antd';
import {GooglePlusOutlined, LockOutlined, TwitterOutlined, UserOutlined, YoutubeOutlined} from '@ant-design/icons';
import axios from 'axios';
import toast from 'react-hot-toast';
import {BASEURL} from '../Constant/Constant';
import {useNavigate} from 'react-router-dom';

const Login = () => {
  const [loginForm] = Form.useForm();
  const navigate = useNavigate();

  const submitData = async (values: any) => {
    try {
      const response = await axios.post(
        `${BASEURL}/access`,
        {
          username: values.email,
          password: values.password,
        },
        {
          headers: {
            Accept: 'applicatin/json',
          },
        },
      );
      if (response?.data && response.data.success) {
        loginForm.resetFields();
        localStorage.setItem('usertoken', response?.data?.data?.token);
        toast.success(response?.data?.message);
        navigate('/dashboard');
      }
    } catch (error: any) {
      loginForm.resetFields();
      toast.error(error?.response?.data?.message || 'Something Went Wrong');
    }
  };

  const onFinish = (values: any) => {
    void submitData(values);
  };

  return (
    <div className="bg-container">
      <Row justify={'center'}>
        <Col xxl={6} xl={12} lg={12} md={12} sm={16} xs={24}>
          <div className="login-container">
            <div className="login-form">
              <div>
                <h2 className="login-title">Welcome to AppManager</h2>
                <p className="login-subtitle">Your Admin Dashboard</p>
              </div>
              <div className="login-form-box">
                <div>
                  <Form form={loginForm} onFinish={onFinish} autoComplete="off">
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Valid Email!',
                        },
                      ]}>
                      <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Email Address"
                        className="custom-input"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Password!',
                        },
                      ]}>
                      <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        className="custom-input"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="submit" type="primary" className="custom-button">
                        Login Now
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div className="sharpnet mb-5"></div>
                <div className="d-flex justify-center align-center">
                  <div className="login-social-icon">
                    <GooglePlusOutlined />
                  </div>
                  <div className="login-social-icon">
                    <TwitterOutlined />
                  </div>
                  <div className="login-social-icon">
                    <YoutubeOutlined />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
