import React from 'react';
import {Navigate} from 'react-router-dom';

interface Props {
  children: any;
}

function Protected({children}: Props) {
  const token = localStorage.getItem('usertoken');
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
}
export default Protected;
