import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {Layout} from 'antd';
import Sidebar from '../Common/SideBar';
import SideDrawer from '../Common/SideBarDrawer';
import TopHeader from '../Common/TopHeader';

const {Content, Sider} = Layout;

const DashboardLayout = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
  const [visible, setVisible] = useState<boolean>(false);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1024);
  };

  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => {
      window.removeEventListener('resize', updateMedia);
    };
  });

  return (
    <>
      {/* {commonData.loading && <FinalLoader loading={commonData.loading} />} */}
      <Layout style={{minHeight: '100vh'}}>
        <Sider className="sidebar-content" collapsed>
          {isDesktop ? (
            <Sidebar />
          ) : (
            <div onClick={handleClose}>
              <SideDrawer setVisible={setVisible} visible={visible} />
            </div>
          )}
        </Sider>

        <Layout
          className={`${
            // eslint-disable-next-line
            isDesktop && `site-layout dashoard-hide`
          }`}>
          <TopHeader setVisible={!isDesktop && setVisible} />
          <Content className="dashboard-content">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default DashboardLayout;
